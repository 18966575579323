<template>
    <el-tooltip content="搜索数据" placement="bottom" effect="light">
        <el-button type="primary" size="small" icon="Search" circle @click="this.edit = true" />
    </el-tooltip>
    <el-dialog v-model="edit" lock-scroll title="搜索数据" :fullscreen='fill_space'>
        <el-form :model="searchForm" :inline="true" label-position="top">
            <el-row>
                <el-col v-for="(item, index) in searchHeads" :key="index" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item :label="item.label" label-position="left" label-width='100%'>
                        <el-input v-model="searchForm[item.property]" v-if="item.type === 'number'" type="number" />
                        <el-input v-model="searchForm[item.property]" v-if="item.type === 'input'" />
                        <el-input v-model="searchForm[item.property]" :rows="2" type="textarea"
                            v-if="item.type === 'textarea'" />
                        <el-select v-model="searchForm[item.property]" multiple collapse-tags collapse-tags-tooltip
                            filterable placeholder="选择" v-if="item.type === 'select'">
                            <el-option v-for="i in item.option" :key="i" :label="i" :value="i" />
                        </el-select>
                        <el-date-picker v-model="searchForm[item.property]" type="daterange" range-separator="-"
                            start-placeholder="开始时间" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
                            end-placeholder="结束时间" :disabled-date="disabledDate" @calendar-change='pickerOptions'
                            :visible-change="timeOptionRange=''" v-if="item.type === 'date-picker'" />
                        <el-date-picker v-model="searchForm[item.property][1]" type="date" placeholder="选择时间"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="-"
                            v-if="item.type === 'one-date-picker'" @change="oneDate(item.property)" />
                        <el-input v-model="searchForm[item.property]" :rows="2" type="input"
                            v-if="item.type === 'disabled'" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="edit = false">取消</el-button>
                <el-button type="primary" @click="searchs">
                    确认
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
    import {
        defineEmits
    } from "vue"
    //搜索框模块
    export default {
        name: "CatSearch",
        props: {
            searchHead: Array,
            searchForm: Object
        },
        mounted() {
            let n = document.body.clientWidth
            if (n <= 800) {
                this.fill_space = true
            } else {
                this.fill_space = false
            }
        },
        data() {
            return {
                timeOptionRange: "",
                fill_space: false,
                edit: false,
                newSearchHead: [],
            }
        },
        computed: {
            searchHeads: function() {
                return this.searchHead.filter((item) => {
                    if (item.search) {
                        this.newSearchHead.push(item.property)
                        return item
                    }
                })
            }
        },
        emits: ['search'],
        methods: {
            oneDate(property) {
                this.searchForm[property][0] = this.searchForm[property][1].slice(0, -2) + '01'
            },
            pickerOptions(time) {
                //保存用户在设置日期选择器选择的第一个日期
                this.timeOptionRange = time[0];
            },
            disabledDate(time) {
                let dataOptionRange = this.timeOptionRange;
                let secondNum = 60 * 60 * 24 * 30 * 1000; // 时间跨度为7天【一年以365天的时间计算】的限制
                if (dataOptionRange) {
                    return time.getTime() > dataOptionRange.getTime() + secondNum || time.getTime() < dataOptionRange
                        .getTime();
                }
            },
            searchs() {
                // 清除无效筛选
                Object.keys(this.searchForm).forEach(key => {
                    let ifv = this.searchForm[key]
                    // 删除非搜索字段
                    // if (!this.newSearchHead.includes(key)) {
                    //     delete this.searchForm[key];
                    // }
                    // 删除空数组
                    if (Array.isArray(ifv) && ifv.length === 0) {
                        delete this.searchForm[key];
                    }
                    // 清除空格 替换中文逗号为英文逗号
                    if (typeof ifv == "string") {
                        this.searchForm[key] = ifv.replace(/\s+/g, '').replace('，', ',')
                    }
                });
                this.newSearchHead = []
                //将数据传递给父组件
                this.$emit('search', this.searchForm)
                this.edit = false
            }
        }
    }
</script>

<style scoped>
</style>